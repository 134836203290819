.news-blog {
    img {
        // width: 285px;
        // height: 285px;
        width: 100%;
      }
      
      p {
        font-size: 13px;
      }
      
      .text {
        padding: 20px;
      }
      
      .col-md-3 {
        padding: 0;
      }
      
      h4 {
        width: fit-content;
        cursor: pointer;
        margin-bottom: 30px;
        &:hover {
          color: #8CAB44;
          transition: .3s;
        }
      }
      
      .container {
        background-color: #EAEAEA;
        margin: 40px auto;
      }
      
      @media (max-width: 1199px) {
        img {
          // width: 240px;
          // height: 240px;
        }
      }
      
      @media (max-width: 991px) {
        p {
          font-size: 11px;
        }
        .text {
          padding: 10px;
        }
        img {
          // width: 180px;
          // height: 180px;
        }
      }
      
      @media (max-width: 767px) {
        p {
          font-size: 15px;
        }
        .text {
          padding: 20px;
        }
        img {
          width: 100%;
          height: 100%;
        }
        .first {
          order: 1;
        }
        .second {
          order: 2;
        }
        .third {
          order: 3;
        }
        .fourth {
          order: 4;
        }
        .fifth {
          order: 5;
        }
        .sixth {
          order: 6;
        }
        .seventh {
          order: 7;
        }
        .eight {
          order: 8;
        }
      }      
}