.help-cause {
    background-color: #00567C;
    width: 100%;
    height: 70px;
    // margin: 40px 0;
    h1 {
      color: white;
      text-align: center;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      font-size: 37px;
      text-transform: uppercase;
    }
  }
  
  @media (max-width: 530px) {
    .help-cause {
      height: 50px;
      h1 {
        font-size: 30px !important;
      }
    }
  }
  