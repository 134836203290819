.blog-page {
    img {
        width: 100%;
    }

    .line {
        visibility: hidden;
    }

    .container {
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .left {
        background-color: #8cab44;
    }
    .col-md-8 {
        padding: 10px 25px;
        color: white;
        margin: auto;
    }
    p {
        text-align: justify;
    }

    .blue {
        &:hover {
            color: #8cab44;
            transition: 0.3s;
            cursor: pointer;
        }
    }

    .green {
        &:hover {
            color: #235c75;
            transition: 0.3s;
            cursor: pointer;
        }
    }

    .col-md-4 {
        padding: 0;
    }

    .right {
        background-color: #235c75;
    }
    span {
        font-weight: 400;
    }

    ._991 {
        display: none;
    }
    ._1191 {
        display: none;
    }

    @media (max-width: 1198px) {
        .mainText {
            display: none;
        }
        ._1191 {
            display: block;
        }
    }

    @media (max-width: 991px) {
        ._991 {
            display: block;
        }
        ._1191 {
            display: none;
        }
    }

    @media (max-width: 840px) {
        .line {
            margin-bottom: 50px;
        }
    }

    @media (max-width: 767px) {
        .first {
            order: 1;
        }
        .second {
            order: 2;
        }
    }
}
