.current-project {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    p {
        margin-bottom: 5px;
        line-height: 1;
    }

    .container {
        padding: 30px 70px;
        padding-bottom: 30px;
    }

    h5 {
        color: #00567c;
        font-size: 30px !important;
        font-weight: 500;
    }
    h4 {
        color: #00567c;
        font-weight: 500;
        font-size: 20px;
        font-size: 25px !important;
    }

    .img-container {
        // outline: 1px solid black;
        height: 100%;
        padding: 20px;
        img {
            width: 100%;
        }
    }

    p {
        font-size: 20px;
        text-align: justify;
    }

    .stats-major {
        display: flex;
        width: fit-content;
        margin: 25px 0;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }

    .funds {
        /* display: flex; */
        font-size: 25px;
        background: white;
        padding: 27px 0;
        border-radius: 50%;
        /* margin: 0 20px; */
        // font-family: "LEMONMILK-Regular";
        font-size: 25px;
        width: 160px;
    }

    .funds-raised {
        margin-right: 50px;
    }

    .funds-target {
        margin-left: 50px;
    }

    .container-circle {
        margin-top: auto;
        position: relative;
        margin-bottom: auto;
    }

    .pc3 {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        // font-family: "LEMONMILK-Regular";
        &::after {
            content: attr(data-percent) "%";
            display: block;
            width: 110px;
            height: 110px;
            background: #ffffff;
            position: absolute;
            top: 5px;
            left: 5px;
            border-radius: 50%;
            color: black;
            text-align: center;
            line-height: 110px;
            font-size: 25px;
        }
        svg {
            width: 120px;
            height: 120px;
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            &::before {
                content: #dfd0d8;
                display: block;
            }
            .percent-circle-inner {
                fill: #dfd0d8;
                stroke: #8cab44;
                stroke-width: 10px;
                stroke-dasharray: 0 376.9908px;
                -webkit-transition: stroke-dasharray 0.6s ease-out;
                transition: stroke-dasharray 0.6s ease-out;
            }
        }
    }

    .hidden {
        display: none;
    }

    .strokeAdd {
        stroke-dasharray: 50;
    }

    @media (max-width: 991px) {
        .container {
            padding: 30px 0;
        }
        .funds-raised {
            margin-right: 20px;
        }

        .funds-target {
            margin-left: 20px;
        }
        .img-container {
            padding: 0;
        }
    }
    @media (max-width: 767px) {
        .stats-major {
            position: relative;
            transform: translateX(-50%);
            left: 50%;
            margin: 25px 0;
        }
    }

    @media (max-width: 512px) {
        .stats-major {
            position: relative;
            transform: translateX(-50%);
            left: 50%;
        }
        .funds {
            font-size: 20px;
            width: 120px;
        }
    }

    @media (max-width: 432px) {
        .col-md-8 {
            h4 {
                font-size: 20px !important;
            }
            h5 {
                font-size: 24px !important;
            }
            p {
                font-size: 18px;
            }
        }
        .funds {
            padding: 15px 0;
            width: 100px;
        }
        .funds-raised {
            margin-right: 10px;
        }
        .funds-target {
            margin-left: 10px;
        }
    }

    @media (max-width: 370px) {
        .funds {
            padding: 10px 0;
            width: 80px;
        }
        .funds-raised {
            margin-right: 5px;
        }
        .funds-target {
            margin-left: 5px;
        }
    }

    @media (max-width: 330px) {
        .funds {
            width: 60px;
        }
    }
}
