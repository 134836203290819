// @import "../../global.scss";

.client-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    // height: 100vh;
    height: 100%;
    width: 300px !important;
    background-color: #f4f7fa;
    z-index: 999999;
    transform: translateX(-100%);
    .logo {
        width: 12rem;
    }
    a {
        color: #000;
        width: fit-content;
        text-decoration: none;
        padding: 10px;
        display: block;
        transition: all 0.3s ease-in-out;
        // font-family: $font-maia;
        // font-size: $font-size-small;
        margin-left: auto;
        margin-right: auto;
        opacity: 1;
        &:hover {
            color: #000;
            opacity: 0.5;
        }
    }
    .fa-close {
        padding: 10px;
        background: #8cab44;
        background: linear-gradient(
            270deg,
            #8cab44 0%,
            #8cab44 50%,
            #8cab44 100%
        );
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // font-size: $font-size-regular;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

.client-sidebar-not-active {
    display: none;
}

.client-sidebar-open {
    transform: translateX(-100%);
    animation: slide-in-left 0.6s ease-in-out forwards;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.client-sidebar-closed {
    transform: translateX(0);
    animation: slide-out-left 0.6s ease-in-out forwards;
}

@keyframes slide-in-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out-left {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
