.projects {
    .project-row {
        display: flex;
        .square-cont {
            width: 6rem;
            padding: 0;
        }
        .project-cont {
            width: calc(100% - 6rem);
            padding: 0;
        }
    }
    .container {
        margin-top: 40px;
    }
    h1 {
        text-transform: capitalize;
    }

    .square {
        background-color: #8cab44;
        padding: 15px 10px;
        font-size: 25px;
        font-weight: 600;
        line-height: 1;
        height: fit-content;
        span {
            font-size: 15px;
            text-transform: uppercase;
        }
    }

    .project {
        width: 90%;
        padding: 20px;
        background-color: #eaeaea;
        p {
            font-size: 13px;
        }
    }

    .col-md-5 {
        margin: 30px;
    }

    .header {
        // text-indent: 65px;
        h3 {
            color: #00567c;
            margin-top: 10px;
            text-transform: capitalize;
        }
    }

    @media (max-width: 1199px) {
        .project {
            width: 90%;
            padding: 10px;
        }
        .header {
            // text-indent: 45px;
        }
    }

    @media (max-width: 991px) {
        .row {
            justify-content: center;
        }
        .project {
            width: 90%;
            p {
                font-size: 11px;
            }
        }
        .header {
            // text-indent: 20px;
            h1 {
                font-size: 30px;
            }
            h3 {
                font-size: 23px;
            }
        }
        .col-md-5 {
            margin: 15px;
            flex: 0 0 45.666667%;
            max-width: 45.666667%;
        }
    }

    @media (max-width: 767px) {
        .project {
            width: 100%;
            p {
                font-size: 13px;
            }
        }
        .header {
            // text-indent: 10px;
        }
        .col-md-5 {
            flex: 0 0 97.666667%;
            max-width: 97.666667%;
        }
    }

    @media (max-width: 540px) {
        .project {
            // width: 70%;
            p {
                font-size: 11px;
            }
        }
        .col-md-5 {
            flex: 0 0 97.666667%;
            max-width: 97.666667%;
        }
    }

    @media (max-width: 400px) {
        .project {
            h3 {
                font-size: 22px;
            }
            p {
                font-size: 10px;
            }
        }
    }

    h1 {
        font-size: 40px !important;
        font-weight: 500 !important;
        text-transform: capitalize !important;
    }

    h3 {
        font-size: 28px !important;
        font-weight: 500 !important;
        text-transform: capitalize !important;
    }
    h4 {
        font-size: 24px !important;
        font-weight: 500 !important;
        text-transform: capitalize !important;
    }
    h5 {
        font-size: 20px !important;
        font-weight: 500 !important;
        text-transform: capitalize !important;
    }
    h6 {
        font-size: 16px !important;
        font-weight: 500 !important;
        text-transform: capitalize !important;
    }
}
