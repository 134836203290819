// @import "../../global.scss";

section {
    width: 100%;
    display: inline-block;
    background: #333;
    height: 50vh;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 700;
    text-decoration: underline;
}

.footer-distributed {
	background: black;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font-size: 1.125rem;
    padding: 3.438rem 3.125rem;
    // font-family: $font-urbanist;
    // font-weight: $font-weight-normal;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
    width: 40%;
}

/* The company logo */

.footer-distributed h3 {
    color: white;
    font-size: 2.25rem;
    margin: 0;
}

.footer-distributed h3 span {
    color: #8cab44;
    // font-family: $font-retro;
    // font-size: $font-size-xxxxl;
}

/* Footer links */

.footer-distributed .footer-links {
    color: white;
    margin: 1.25rem 0 0;
    padding: 0;
}

.footer-distributed .footer-links a {
    display: inline-block;
    margin-right: 0.313rem;
    line-height: 1.8;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
    transition: ease-in-out 0.3s;
    &:hover {
        color: #8cab44;
    }
}

.footer-distributed .footer-company-name {
    color: white;
    font-size: 0.875rem;
    font-weight: normal;
    margin: 0;
    margin-bottom: 1rem;
}

/* Footer Center */

.footer-distributed .footer-center {
    width: 35%;
}

.footer-distributed .footer-center i {
    background-color: #33383b;
    color: white;
    font-size: 1.25rem;
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 50%;
    text-align: center;
    line-height: 2.625rem;
    margin: 0.625rem 0.938rem;
    vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
    font-size: 1.25rem;
    line-height: 2.375rem;
}

.footer-distributed .footer-center .special i {
    color: #8cab44;
}

.footer-distributed .footer-center .special a {
    color: white;
}

.footer-distributed .footer-center p {
    font-size: 1;
    display: inline-block;
    color: white;
    font-weight: 400;
    vertical-align: middle;
    margin: 0;
}

.footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 1rem;
    line-height: 2;
}

.footer-distributed .footer-center p a {
    color: white;
    text-decoration: none;
}

.footer-distributed .footer-links a:before {
    content: "|";
    font-weight: 300;
    font-size: 1.125rem;
    left: 0;
    color: white;
    display: inline-block;
    padding-right: 0.313rem;
}

.footer-distributed .footer-links .link-1:before {
    content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
    width: 20%;
}

.footer-distributed .footer-company-about {
    line-height: 1.25rem;
    color: white;
    font-size: 0.813rem;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-about span {
    display: block;
    color: white;
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 1.25rem;
}

.footer-distributed .footer-icons {
    margin-top: 1.563rem;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 2.188rem;
    height: 2.188rem;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;

    font-size: 1.25rem;
    color: white;
    text-align: center;
    line-height: 2.188rem;

    margin-right: 0.188rem;
    margin-bottom: 0.313rem;
    transition: ease-in-out 0.3s;
    &:hover {
        background-color: #8cab44;
    }
}

.smallS {
    display: none;
}

.h-b {
    img {
        width: 10rem;
    }
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 61.99875em) {
    .smallS {
        display: block;
    }
    .bigS {
        display: none;
    }
    .footer-distributed {
        font: bold 0.875em sans-serif;
    }

    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        display: block;
        width: 100%;
        margin-bottom: 2.5em;
        text-align: center;
    }

    .footer-distributed .footer-center i {
        margin-left: 0;
    }
}
