// @import "../../global.scss";

.absolute {
    position: absolute;
    .navbar {
        .navbar-links {
            .nav-link {
                color: white !important;
                &:hover {
                    color: #8cab44 !important;
                }
            }
        }
    }
}

.relative {
    position: relative;
    .navbar {
        .navbar-links {
            .nav-link {
                color: black !important;
                &:hover {
                    color: #8cab44  !important;
                }
            }
        }
    }
}

.web-navbar {
    z-index: 99999999999999999;
    width: 100%;
    .navbar {
        padding: 1.5rem 4rem;
        .logo {
            width: 17rem;
        }
        .navbar-links {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .nav-link {
                font-size: 1.25rem;
                font-weight: 700;
                text-decoration: none;
                color: white;
                padding: 0.5rem 1rem;
                &:hover {
                    color: #8cab44;
                }
            }
        }
    }
    // .me-auto {
    //     margin-right: auto!important;
    // }
}

// Large devices (desktops, less than 1400px)
@media (max-width: 87.49875em) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 74.99875em) {
    .web-navbar {
        .navbar {
            padding: 1.5rem 2.5rem;
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 61.99875em) {
    .web-navbar {
        .navbar {
            padding: 1.5rem 1rem;
            .logo {
                display: none;
            }
        }
        .fa-bars {
            color: #f4f7fa;
            // font-size: $font-size-small;
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 47.99875em) {
    .web-navbar {
        .navbar {
            .logo {
                width: 13rem;
            }
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 35.99875em) {
    .web-navbar {
        .navbar {
            padding: 1.5rem 0rem;
            .logo {
                width: 11rem;
            }
        }
    }
}
