.our-mission {
    .container-fluid {
        padding: 0;
        position: relative;
        background: white;
        overflow: hidden;
    }
    .container {
        padding: 30px 150px;
        z-index: 1;
        position: relative;
        h1 {
            // margin: 30px 0;
            margin-bottom: 30px;
            color: #8cab44;
        }
        p {
            line-height: 1.5;
        }
    }

    @media (max-width: 1199px) {
        .container {
            padding: 30px 80px;
        }
    }

    @media (max-width: 991px) {
        .container {
            padding: 30px 0;
        }
    }

    @media (max-width: 767px) {
        .container {
            h1 {
                font-size: 33px;
            }
            h5 {
                font-size: 18px;
            }
            p {
                font-size: 15px;
                padding: 30px 20px;
            }
        }
    }

    @media (max-width: 767px) {
        .container {
            h1 {
                font-size: 30px;
            }
            h5 {
                font-size: 16px;
            }
            p {
                font-size: 13px;
            }
        }
    }
}
