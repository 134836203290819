.president-message {
    .container-fluid {
        background-image: url('./Hands.jpg');
        padding: 50px;
        background-repeat: no-repeat;
        background-position: 50% 0;
        -ms-background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;
    }

    h3 {
        font-size: 28px;
        font-weight: 500;
    }
    h5 {
        font-size: 20px;
        font-weight: 500;
    }
    h6 {
        font-size: 16px;
        font-weight: 500;
    }

    img {
        width: 100px;
        border-radius: 100px;
    }

    .container {
        padding: 30px;
        background-color: white;
        max-width: 900px;
    }

    @media (max-width: 700px) {
        h3 {
            font-size: 23px;
        }
        h5 {
            font-size: 17px;
        }
        h6,
        p {
            font-size: 13px;
        }
        .container {
            padding: 10px;
        }
    }

    @media (max-width: 500px) {
        h3 {
            font-size: 23px;
        }
        h5 {
            font-size: 17px;
        }
        h6,
        p {
            font-size: 13px;
        }
        .container {
            padding: 10px;
        }
        .container-fluid {
            padding: 50px 10px;
        }
    }

    @media (max-width: 400px) {
        .container-fluid {
            padding: 30px 10px;
        }
    }
}
