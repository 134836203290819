.inter-buttons {
    .container {
        margin: 70px auto;
        margin-bottom: 120px;
        div {
            p {
                margin-top: 10px;
                font-size: 20px;
            }
            a {
                padding: 20px 30px;
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 600;
                border-radius: 18px;
                border: none;
                background-color: #8cab44;
                color: black;
                top: 20px;
                cursor: pointer;
                transition-duration: 0.4s;
                position: relative;
                &:hover {
                    background-color: white;
                    border: 2px solid #8cab44;
                    color: #8cab44;
                    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
                }
            }
        }
    }

    @media (max-width: 991px) {
        .container {
            div {
                p {
                    font-size: 15px;
                }
                a {
                    padding: 15px;
                    white-space: nowrap;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .container {
            div {
                p {
                    font-size: 15px;
                }
                a {
                    padding: 15px;
                    white-space: nowrap;
                }
            }
            .col-md-4 {
                margin: 20px 0;
            }
        }
    }
}
