.departments {
    .departments-section {
        text-align: center;
        // margin-bottom: 40px;
        padding: 100px 0;
        margin: 60px 0;
        margin-bottom: 0;
        background-image: url("./dep_back.jpg");
        background-repeat: no-repeat;
        background-position: 50% 0;
        -ms-background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;
        a {
            text-decoration: none;
            color: black;
            margin: 15px;
            padding: 0;
            div {
                top: 50%;
                transform: translateY(-50%);
                position: relative;
            }
        }
    }

    .departments-list {
        height: 430px;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        .dep {
            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }

    .dep-head {
        // line-height: 2px;
        top: 45px;
        font-size: 50px;
        position: relative;
        color: white;
        text-shadow: rgb(0, 0, 0) 1px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px,
            rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px,
            rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px,
            rgb(0, 0, 0) 0.96017px -0.279415px 0px;
    }

    .dep {
        width: 150px;
        height: 150px;
        background-color: #fff;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70% auto;
        text-transform: uppercase;
        font-weight: 600;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        cursor: pointer;
        box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.4);
        &:hover {
            box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.4), 0 0 0 10px #00567c;
            &:nth-of-type(even) {
                box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.4), 0 0 0 10px #8cab44;
            }
        }
    }

    // .dep:hover:nth-of-type(even) {
    //   box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.4), 0 0 0 10px #8CAB44 !important;
    // }

    // .dep:hover:nth-of-type(odd) {
    //   box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.4), 0 0 0 10px #00567C !important;
    // }

    // .blue {
    //   &:hover {
    //     box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.4), 0 0 0 10px #00567C;
    //   }
    // }

    // .green {
    //   &:hover {
    //     box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.4), 0 0 0 10px #8CAB44;
    //   }
    // }

    .main-heading {
        margin: 0 !important;
        margin-top: 40px !important;
        // margin-bottom: 40px !important;
        color: #8cab44 !important;
    }

    @media (max-width: 767px) {
        .departments-section {
            padding: 50px;
        }
    }
}
