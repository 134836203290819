@import '../../global.scss';

.admin-login-container {
    // padding: 0;
    img {
        height: inherit;
        position: absolute;
        right: 0;
    }
    position: relative;
    background: #8cab44;
    // background: white;
    // overflow: hidden;
    height: 100vh;
    // &::before {
    //   content: ' ';
    // display: block;
    // position: absolute;
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: 100%;
    // z-index: 1;
    // // opacity: 0.3;;
    // filter: blur(0px)
    // grayscale(100%);
    // // background-image: url(/assets/background1.jpg);
    // background-repeat: no-repeat;
    // background-position: 50% 0;
    // -ms-background-size: cover;
    // -o-background-size: cover;
    // -moz-background-size: cover;
    // -webkit-background-size: cover;
    // background-size: cover;
    // }
    .admin-card-formcontrol {
        margin: 10px 0;
        width: 100%;
        label {
            transform: translate(0, -9px) scale(0.75);
        }
    }
    .admin-login-base {
        padding: 50px 30px;
    }
    .admin-login-card {
        position: absolute;
        z-index: 2;
        width: 450px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .admin-login-logo {
        width: 15rem;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 30px;
    }
    .admin-login-button {
        top: 20px;
        padding-left: 30px;
        padding-right: 30px;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
    }
  }