.slideshow {
    .swiper-button-prev, .swiper-button-next {
        color: #8cab44;
    }
    .carousel1 {
        margin-bottom: 50px;
    }
    @media (max-width: 991px) {
        .carousel {
            padding: 65px 0;
        }
    }

    img {
        width: 100%;
        height: 100%;
    }
}
