.page {
    position: absolute;
    background-color: white;
    width: 100%;
}

.page-enter {
    opacity: 0;
    // transform: scale(1.1);
}

.page-enter-active {
    opacity: 1;
    // transform: scale(1);
    transition: opacity 250ms;
}

.page-exit {
    opacity: 1;
    // transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    // transform: scale(0.9);
    transition: opacity 250ms;
}

.unhide-1200 {
    display: none;
}

.hide-992 {
    display: block;
}

.unhide-992 {
    display: none;
}

.unhide-768 {
    display: none;
}
.unhide-576 {
    display: none;
}

.margin-global-top-1 {
    margin-top: 1rem;
}

// Devices (less than 1700px)
@media (max-width: 106.1875em) {
}

// Devices (less than 1600px)
@media (max-width: 99.9375em) {
}

// Devices (less than 1500px)
@media (max-width: 93.6875em) {
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 87.49875em) {
    .hide-1400 {
        display: none;
    }
}

// Devices (less than 1300px)
@media (max-width: 81.1875em) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 74.99875em) {
    .hide-1200 {
        display: none;
    }
    .unhide-1200 {
        display: block;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 61.99875em) {
    .hide-992 {
        display: none;
    }
    .unhide-992 {
        display: block;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 47.99875em) {
    .hide-768 {
        display: none;
    }
    .unhide-768 {
        display: block;
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 35.99875em) {
    .unhide-576 {
        display: block;
    }
}

// // XX-Large devices (larger desktops)
// // No media query since the xxl breakpoint has no upper bound on its width
