.hand-background {
    position: relative;
    .background {
        background-image: url('./main.jpg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: 50% 0;
        -ms-background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;
        width: 100%;
        height: 100vh;
        color: black;
        position: relative;
    }

    .main-text {
        position: absolute;
        text-align: center;
        width: fit-content;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: white;
        text-transform: uppercase;
        h1 {
            font-size: 80px !important;
            line-height: 1.5;
        }
        h3 {
            font-size: 20px;
        }
        p {
            font-weight: 100;
        }
    }

    img {
        display: none;
    }

    .donate-container {
        margin-top: 70px;
        .buttonStyle {
            border-radius: 18px;
            border: none;
            background-color: #8cab44;
            color: black;
            font-weight: bold;
            padding: 15px 50px;
            text-align: center;
            cursor: pointer;
            text-decoration: none;
            font-size: 22px;
            transition-duration: 0.3s;
            &:hover {
                background-color: rgba(0, 0, 0, 0.3);
                box-shadow: black;
                border: 2px solid #8cab44;
                color: #8cab44;
                box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
            }
            &:active {
                background-color: darkgrey;
            }
        }
    }

    @media (max-width: 991px) {
        img {
            display: block;
            margin: 14px auto;
            width: 235px;
        }
    }

    @media (max-width: 767px) {
        .main-text {
            h1 {
                font-size: 50px !important;
            }
            h3 {
                font-size: 17px !important;
            }
        }
        .donate-container {
            margin-top: 35px;
            .buttonStyle {
                padding: 10px 35px;
            }
        }
    }

    @media (max-width: 505px) {
        .main-text {
            top: 45%;
            h1 {
                font-size: 40px !important;
            }
            h3 {
                font-size: 15px !important;
            }
        }
    }

    @media (max-width: 425px) {
        .main-text {
            h1 {
                font-size: 35px !important;
            }
            h3,
            p {
                font-size: 13px !important;
            }
        }
        .donate-container {
            .buttonStyle {
                font-size: 18px;
            }
        }
    }

    @media (max-width: 370px) {
        .main-text {
            h1 {
                font-size: 30px !important;
            }
        }
        .donate-container {
            .buttonStyle {
                font-size: 17px;
            }
        }
    }

    @media (max-width: 320px) {
        .main-text {
            h1 {
                font-size: 25px !important;
            }
        }
    }
}
