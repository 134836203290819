.our-story {
    .container-fluid {
        padding: 0;
        position: relative;
        background: white;
        overflow: hidden;
        &::before {
            content: " ";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0.3;
            background-image: url('./dep_back.jpg');
            background-repeat: no-repeat;
            background-position: 50% 40%;
            -ms-background-size: cover;
            -o-background-size: cover;
            -moz-background-size: cover;
            -webkit-background-size: cover;
            background-size: cover;
        }
    }
    .container {
        padding: 30px 150px;
        z-index: 1;
        position: relative;
        h1 {
            margin-bottom: 30px;
            color: #00567c;
        }
        p {
            line-height: 1.5;
        }
    }

    @media (max-width: 1199px) {
        .container {
            padding: 30px 80px;
        }
    }

    @media (max-width: 991px) {
        .container {
            padding: 30px 20px;
        }
    }

    @media (max-width: 767px) {
        .container {
            h1 {
                font-size: 33px;
            }
            h5 {
                font-size: 18px;
            }
            p {
                font-size: 15px;
            }
        }
    }

    @media (max-width: 767px) {
        .container {
            h1 {
                font-size: 30px;
            }
            h5 {
                font-size: 16px;
            }
            p {
                font-size: 13px;
            }
        }
    }
}
