.contact-us-page {
    .container {
        margin: 0 auto;
        margin-top: 75px;
    }

    img.logo {
        width: 300px;
        margin: 20px 0;
        margin-top: 50px;
    }

    .icon {
        font-size: 60px;
    }

    .icons {
        margin: 40px 0;
    }

    .whatsapp {
        color: #25d366;
    }

    .instagram {
        display: inline-block;
        width: 60px;
        height: 60px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 40px;
        color: #fff;
        line-height: 60px;
        font-size: 50px;
        background: #d6249f;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
    }

    .facebook {
        color: #3b5998;
    }

    @media (max-width: 991px) {
        h1 {
            font-size: 30px !important;
        }
        img.logo {
            width: 250px;
        }
    }

    @media (max-width: 767px) {
        .container {
            margin-top: 0;
        }
        h1 {
            font-size: 25px !important;
        }
        img.logo {
            width: 200px;
        }
    }

    @media (max-width: 650px) {
        h1 {
            margin-top: 70px;
        }
    }
}
